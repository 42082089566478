// src/pages/ChatPage.js
import React from 'react';
import ChatRoom from '../components/ChatRoom';

function ChatPage({ user }) {
  return (
    <div>
      <h2>Chat Room</h2>
      <ChatRoom user={user} />
    </div>
  );
}

export default ChatPage;