import React, { useMemo, useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Circle, Popup } from 'react-leaflet';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import Tutorial from './Tutorial'; // Importer le composant Tutorial


// Définir l'icône personnalisée pour le marqueur
const markerIcon = L.icon({
  iconUrl: '/Curseur Meevel.png',
  iconSize: [60, 60],
  iconAnchor: [15, 30],
});

const groupUsersByZone = (users, gridSize) => {
  const grouped = {};

  users.forEach((user) => {
    if (!isNaN(user.lat) && !isNaN(user.lng)) {
      const latZone = Math.floor(user.lat / gridSize) * gridSize;
      const lngZone = Math.floor(user.lng / gridSize) * gridSize;
      const key = `${latZone},${lngZone}`;

      if (!grouped[key]) {
        grouped[key] = { users: [], latZone, lngZone };
      }
      grouped[key].users.push(user);
    }
  });

  return Object.values(grouped).map((group) => {
    const count = group.users.length;
    const lat = group.users.reduce((sum, user) => sum + user.lat, 0) / count;
    const lng = group.users.reduce((sum, user) => sum + user.lng, 0) / count;
    return { count, lat, lng, users: group.users };
  });
};

const haversineDistance = (coords1, coords2) => {
  const toRad = (x) => (x * Math.PI) / 180;
  const R = 6371;

  const dLat = toRad(coords2.lat - coords1.lat);
  const dLon = toRad(coords2.lng - coords1.lng);
  const lat1 = toRad(coords1.lat);
  const lat2 = toRad(coords2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

function Map({ users: virtualUsers }) {
  const { currentUser } = useAuth();
  const [zoom, setZoom] = useState(() => parseInt(sessionStorage.getItem('mapZoom')) || 10);
  const [initialLocation, setInitialLocation] = useState(null);
  const [nearbyUsers, setNearbyUsers] = useState([]);
  const [users, setUsers] = useState(virtualUsers);
  const [markerPosition, setMarkerPosition] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    const fetchUserLocationFromFirestore = async () => {
      if (currentUser) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Charger les coordonnées temporaires si elles existent
          const firestoreLocation = userData.tempLat && userData.tempLng
            ? { lat: userData.tempLat, lng: userData.tempLng } // Coordonnées temporaires
            : { lat: userData.lat, lng: userData.lng }; // Coordonnées classiques

          setInitialLocation(firestoreLocation);
          setZoom(11);
          sessionStorage.setItem('mapPosition', JSON.stringify(firestoreLocation));
          sessionStorage.setItem('mapZoom', '13');
        }
      }
    };

    fetchUserLocationFromFirestore();
  }, [currentUser]);

  useEffect(() => {
    if (initialLocation) {
      if (mapRef.current) {
        mapRef.current.setView([initialLocation.lat, initialLocation.lng], zoom);
      }
    }
  }, [initialLocation, zoom]);

  useEffect(() => {
    const fetchFirestoreUsers = async () => {
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, 'users'));
      const firestoreUsers = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const evaluationsSnapshot = await getDocs(collection(db, `users/${doc.id}/receivedEvaluations`));
          data.receivedEvaluations = evaluationsSnapshot.docs.map((evalDoc) => evalDoc.data());
          return data;
        })
      );
      const filteredUsers = firestoreUsers.filter((user) => user.id !== currentUser?.uid);
      setUsers(filteredUsers);
    };

    fetchFirestoreUsers();
  }, [currentUser]);

  const gridSize = zoom < 10 ? 1 : zoom < 12 ? 0.5 : 0.1;
  const userGroups = useMemo(() => groupUsersByZone(users, gridSize), [users, gridSize]);

  const getRadius = (count) => {
    if (count < 5) return 5000;
    if (count < 10) return 10000;
    return 15000;
  };

  useEffect(() => {
    const updatedLocation = markerPosition || initialLocation;
    if (updatedLocation) {
      const newNearbyUsers = users
        .filter((user) => !isNaN(user.lat) && !isNaN(user.lng))
        .map((user) => ({
          ...user,
          distance: haversineDistance(updatedLocation, { lat: user.lat, lng: user.lng }),
        }))
        .sort((a, b) => a.distance - b.distance);
      setNearbyUsers(newNearbyUsers);
    }
  }, [initialLocation, users, markerPosition]);

  const handleCircleClick = (group) => {
    const updatedLocation = markerPosition || initialLocation;
    const usersWithDistance = group.users
      .filter((user) => !isNaN(user.lat) && !isNaN(user.lng))
      .map((user) => ({
        ...user,
        distance: haversineDistance(updatedLocation, { lat: user.lat, lng: user.lng }),
      }))
      .sort((a, b) => a.distance - b.distance);
    setNearbyUsers(usersWithDistance);
  };

  const formatLastLogin = (lastLogin) => {
    if (!lastLogin || !lastLogin.seconds) return 'N/A';
    const date = new Date(lastLogin.seconds * 1000);
    return date.toLocaleString();
  };

  const getPositiveFeedback = (user) => {
    if (user.receivedEvaluations && user.receivedEvaluations.length > 0) {
      const positiveFeedbacks = user.receivedEvaluations.flatMap(
        (evaluation) => evaluation.positiveFeedback || []
      );
      if (positiveFeedbacks.length > 0) {
        return positiveFeedbacks[0];
      }
    }
    return null;
  };

  const resetToSavedLocation = async () => {
    if (currentUser) {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const firestoreLocation = { lat: userData.lat, lng: userData.lng };
        setInitialLocation(firestoreLocation);
        setZoom(13);
        setMarkerPosition(null);
        sessionStorage.setItem('mapPosition', JSON.stringify(firestoreLocation));
        sessionStorage.setItem('mapZoom', '13');

        if (mapRef.current) {
          mapRef.current.setView([firestoreLocation.lat, firestoreLocation.lng], 13);
        }
      }
    }
  };

  return (
    <>
      <div className="map-container">
        <MapContainer
          center={initialLocation ? [initialLocation.lat, initialLocation.lng] : [0, 0]}
          zoom={zoom}
          className="leaflet-container"
          ref={mapRef}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
            mapInstance.on('zoomend', () => {
              const newZoom = mapInstance.getZoom();
              setZoom(newZoom);
              sessionStorage.setItem('mapZoom', newZoom.toString());
              console.log('Zoom changed:', newZoom);
            });

            mapInstance.on('moveend', () => {
              const center = mapInstance.getCenter();
              setInitialLocation({ lat: center.lat, lng: center.lng });
              sessionStorage.setItem('mapPosition', JSON.stringify(center));
              console.log('Map moved to:', center);

              // Mettre à jour Firestore avec la position temporaire
              if (currentUser) {
                const db = getFirestore();
                const userDocRef = doc(db, 'users', currentUser.uid);
                updateDoc(userDocRef, {
                  tempLat: center.lat,
                  tempLng: center.lng,
                })
                  .then(() => {
                    console.log('Firestore updated with new center position:', center);
                  })
                  .catch((error) => {
                    console.error('Error updating Firestore:', error);
                  });
              }
            });
          }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
          />
          {markerPosition && (
            <Marker
              position={markerPosition}
              icon={markerIcon}
              draggable={true}
              eventHandlers={{
                dragend: (e) => {
                  const newMarkerPosition = e.target.getLatLng();
                  setMarkerPosition(newMarkerPosition);
                  sessionStorage.setItem('mapPosition', JSON.stringify(newMarkerPosition));

                  // Mettre à jour Firestore avec la position temporaire
                  if (currentUser) {
                    const db = getFirestore();
                    const userDocRef = doc(db, 'users', currentUser.uid);
                    updateDoc(userDocRef, {
                      tempLat: newMarkerPosition.lat,
                      tempLng: newMarkerPosition.lng,
                    })
                      .then(() => {
                        console.log('Firestore updated with new marker position:', newMarkerPosition);
                      })
                      .catch((error) => {
                        console.error('Error updating Firestore:', error);
                      });
                  }
                },
              }}
            />
          )}
          {userGroups.map((group, index) => (
            <Circle
              key={index}
              center={[group.lat, group.lng]}
              radius={getRadius(group.count)}
              pathOptions={{
                color: 'blue',
                fillColor: 'blue',
                fillOpacity: 0.5,
                opacity: 0,
              }}
              eventHandlers={{
                click: () => handleCircleClick(group),
              }}
            >
              <Popup>
                {group.count} {group.count > 1 ? 'people' : 'person'} here
              </Popup>
            </Circle>
          ))}
        </MapContainer>

        {/* Boutons pour ajouter un marqueur et réinitialiser la position */}
        <button
          className="add-marker-button"
          onClick={() => {
            const center = mapRef.current.getCenter();
            setMarkerPosition(center);
            sessionStorage.setItem('tempMarkerPosition', JSON.stringify(center));

            // Mettre à jour Firestore avec la position temporaire
            if (currentUser) {
              const db = getFirestore();
              const userDocRef = doc(db, 'users', currentUser.uid);
              updateDoc(userDocRef, {
                tempLat: center.lat,
                tempLng: center.lng,
              })
                .then(() => {
                  console.log('Firestore updated with new marker center position:', center);
                })
                .catch((error) => {
                  console.error('Error updating Firestore:', error);
                });
            }
          }}
        >
          Add Marker Position
        </button>

        <button
          className="reset-location-button"
          onClick={resetToSavedLocation}
        >
          Reset to Saved Location
        </button>
      </div>

      {nearbyUsers.length > 0 && (
        <div className="nearby-users-carousel">
          <Carousel controls={true} indicators={false} interval={null}>
            {nearbyUsers.map((user, index) => (
              <Carousel.Item key={index}>
                <div className="d-flex justify-content-around w-100">
                  <div className="user-item text-center position-relative">
                    <div className="status-indicator">
                      <span
                        className={`status-dot ${
                          user.onlineStatus === 'online' ? 'online' : 'offline'
                        }`}
                      ></span>
                      <span className="status-text">
                        {user.onlineStatus === 'online'
                          ? 'Connected'
                          : `Offline from ${formatLastLogin(user.lastLogin)}`}
                      </span>
                    </div>
                    <Link to={`/user-profile/${user.id}`} className="profile-container">
                      <img
                        src={user.profile?.profilePicture || 'path_to_default_image.jpg'}
                        alt="Profile"
                        className="user-profile-image"
                      />
                      <h5>
                        {user.profile?.firstName ?? 'N/A'}{' '}
                        {user.profile?.lastName ? user.profile.lastName.charAt(0) : ''}.
                      </h5>
                    </Link>
                    {getPositiveFeedback(user) && (
                      <div className="feedback-badge">{getPositiveFeedback(user)}</div>
                    )}
                    {user.meetPoints && (
                      <div className="meetpoints-badge">
                        <span className="star-icon">★</span>
                        <span className="meetpoints-number">{user.meetPoints}</span> Meetpoints
                      </div>
                    )}
                    <p>
                      {user.distance
                        ? `${user.distance.toFixed(1)} km from your position`
                        : 'N/A'}
                    </p>
                    <p>Age: {user.profile?.age ?? 'N/A'}</p>
                    <p>Nationality: {user.profile?.nationality ?? 'N/A'}</p>
                    <p>Bio: {user.profile?.bio ?? 'N/A'}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <Tutorial />
        </div>
      )}
    </>
  );
}

export default Map;