import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import './LoginEmail.css';

function LoginEmail({ setUser }) {
  const db = getFirestore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [geoError, setGeoError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User detected:', user.uid); // Log pour vérifier l'utilisateur détecté

        if (!user.emailVerified) {
          console.log('Email not verified for user:', user.uid); // Log en cas de non-vérification de l'email
          setError('Please verify your email before logging in.');
          await auth.signOut();
          return;
        }

        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          console.log('User document exists in Firestore for:', user.uid); // Log si l'utilisateur existe déjà dans Firestore
          const userData = userDoc.data();
          console.log('User data retrieved:', userData); // Log des données utilisateur récupérées

          // Gérer la réactivation du compte seulement si le champ "active" est défini et faux
          if (Object.prototype.hasOwnProperty.call(userData, 'active') && !userData.active) {
            console.log('User account is inactive:', user.uid); // Log si le compte est inactif
            const reactivate = window.confirm(
              'Your account is currently deactivated. Would you like to reactivate it?'
            );
            if (reactivate) {
              await updateDoc(userRef, { active: true, onlineStatus: 'online' });
              console.log('User account reactivated:', user.uid); // Log de réactivation du compte
              setError('Your account has been reactivated!');
            } else {
              console.log('User chose not to reactivate the account:', user.uid); // Log si l'utilisateur ne veut pas réactiver
              await auth.signOut();
              return;
            }
          }

          // Rediriger vers les différentes pages en fonction de l'état du profil
          if (!userData.termsAccepted) {
            console.log('Redirecting to terms acceptance page.'); // Log de redirection vers l'acceptation des termes
            navigate('/terms-acceptance');
          } else if (!userData.isProfileComplete) {
            console.log('Redirecting to profile form page.'); // Log de redirection vers le formulaire de profil
            navigate('/profile-form');
          } else {
            console.log('Login successful. Redirecting to accueil.'); // Log de succès de la connexion
            setUser(user);
            navigate('/accueil');
          }
        } else {
          console.log('User document does not exist in Firestore. Creating new user document for:', user.uid); // Log si l'utilisateur n'existe pas dans Firestore
          // Créer un document utilisateur avec l'UID seulement si le compte n'existe pas
          await setDoc(userRef, {
            id: user.uid,
            email: user.email,
            name: user.displayName || 'Anonymous',
            verified: user.emailVerified,
            createdAt: new Date(),
            lastLogin: new Date(),
            onlineStatus: 'online',
            isProfileComplete: false,
            termsAccepted: false,
            active: true,
            profilePicture: '',
            receivedEvaluations: [],
            profile: {
              age: null,
              nationality: '',
              favoriteDestinations: [],
              hobbies: '',
            },
          });
          console.log('New user document created. Redirecting to terms acceptance page.'); // Log de création de document utilisateur
          navigate('/terms-acceptance');
        }
      } else {
        console.log('No user detected. Clearing local storage.'); // Log si aucun utilisateur détecté
        localStorage.removeItem('authToken');
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [navigate, setUser, db]);

  const handleLogin = () => {
    console.log('Attempting to log in with email:', email); // Log lors de la tentative de connexion
    signInWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        const user = result.user;
        const token = await user.getIdToken();
        localStorage.setItem('authToken', token);

        if (!user.emailVerified) {
          console.log('Email not verified for user:', user.uid); // Log en cas de non-vérification de l'email
          setError('Please verify your email before logging in.');
          await auth.signOut();
          return;
        }

        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          console.log('User document exists in Firestore for:', user.uid); // Log si l'utilisateur existe déjà dans Firestore
          const userData = userDoc.data();
          console.log('User data retrieved:', userData); // Log des données utilisateur récupérées

          // Gérer la réactivation du compte seulement si le champ "active" est défini et faux
          if (Object.prototype.hasOwnProperty.call(userData, 'active') && !userData.active) {
            console.log('User account is inactive:', user.uid); // Log si le compte est inactif
            const reactivate = window.confirm(
              'Your account is currently deactivated. Would you like to reactivate it?'
            );
            if (reactivate) {
              await updateDoc(userRef, { active: true, onlineStatus: 'online' });
              console.log('User account reactivated:', user.uid); // Log de réactivation du compte
              setError('Your account has been reactivated!');
            } else {
              console.log('User chose not to reactivate the account:', user.uid); // Log si l'utilisateur ne veut pas réactiver
              await auth.signOut();
              return;
            }
          }

          try {
            const { lat, lng } = await getUserLocation();
            await updateDoc(userRef, {
              lat: lat,
              lng: lng,
              lastLogin: new Date(),
              onlineStatus: 'online',
            });

            // Rediriger vers les différentes pages en fonction de l'état du profil
            if (!userData.termsAccepted) {
              console.log('Redirecting to terms acceptance page.');
              navigate('/terms-acceptance');
            } else if (!userData.isProfileComplete) {
              console.log('Redirecting to profile form page.');
              navigate('/profile-form');
            } else {
              console.log('Login successful. Redirecting to accueil.');
              setUser(user);
              navigate('/accueil');
            }
          } catch (locationError) {
            console.error('Geolocation error:', locationError.message);
            setGeoError('Geolocation is required for the best experience. Please enable it in your browser settings.');
          }
        } else {
          console.log('User document does not exist in Firestore. Creating new user document for:', user.uid);
          await setDoc(userRef, {
            id: user.uid,
            email: user.email,
            name: user.displayName || 'Anonymous',
            verified: user.emailVerified,
            createdAt: new Date(),
            lastLogin: new Date(),
            onlineStatus: 'online',
            isProfileComplete: false,
            termsAccepted: false,
            active: true,
            profilePicture: '',
            receivedEvaluations: [],
            profile: {
              age: null,
              nationality: '',
              favoriteDestinations: [],
              hobbies: '',
            },
          });

          console.log('New user document created. Redirecting to terms acceptance page.');
          navigate('/terms-acceptance');
        }

        window.addEventListener('beforeunload', () => {
          console.log('Setting user status to offline before unload.');
          updateDoc(userRef, {
            onlineStatus: 'offline',
          });
        });
      })
      .catch((error) => {
        console.error('Error during login:', error.message);
        setError(error.message);
      });
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError('Please enter your email to reset your password.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent! Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error.message);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  const getUserLocation = () => {
    console.log('Attempting to get user location...');
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('User location obtained:', position.coords);
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Geolocation error:', error.message); // Log de l'erreur de géolocalisation
          reject(error);
        }
      );
    });
  };

  return (
    <div className="login-email-container">
      <h2>Login with Email</h2>
      {location.state?.message && <p className="info-message">{location.state.message}</p>}
      {error && <p className="error-message">{error}</p>}
      {geoError && <p className="error-message">{geoError}</p>}
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin} className="btn-primary">Login</button>
      <button onClick={handleResetPassword} className="btn-link">Forgot Password?</button>
      <button onClick={() => navigate('/')} className="btn-secondary">Back</button>
    </div>
  );
}

export default LoginEmail;