// src/Pages/FriendsPage.js
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, onSnapshot, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './FriendsPage.css';
import { Link } from 'react-router-dom';

function FriendsPage() {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', currentUser.uid);

      const unsubscribe = onSnapshot(userDocRef, async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const notificationsData = userData.notifications || [];
          const enrichedNotifications = await Promise.all(
            notificationsData.map(async (notification) => {
              if (notification.type === 'friend_request') {
                const fromUserDoc = await getDoc(doc(db, 'users', notification.from));
                if (fromUserDoc.exists()) {
                  const fromUser = fromUserDoc.data();
                  notification.fromName = `${fromUser.profile.firstName} ${fromUser.profile.lastName.charAt(0)}.`;
                  notification.fromProfilePicture = fromUser.profile.profilePicture || '';
                } else {
                  notification.fromName = 'Unknown User';
                  notification.fromProfilePicture = '';
                }
              }
              return notification;
            })
          );

          const friendsData = userData.friends || [];
          const enrichedFriends = await Promise.all(
            friendsData.map(async (friendId) => {
              const friendDoc = await getDoc(doc(db, 'users', friendId));
              if (friendDoc.exists()) {
                const friend = friendDoc.data();
                return {
                  id: friendId,
                  name: `${friend.profile.firstName} ${friend.profile.lastName.charAt(0)}.`,
                  profilePicture: friend.profile.profilePicture || ''
                };
              } else {
                return { id: friendId, name: 'Unknown User', profilePicture: '' };
              }
            })
          );

          const friendRequestsData = userData.receivedRequests || [];
          const enrichedFriendRequests = await Promise.all(
            friendRequestsData.map(async (requestId) => {
              const requestDoc = await getDoc(doc(db, 'users', requestId));
              if (requestDoc.exists()) {
                const requestUser = requestDoc.data();
                return {
                  id: requestId,
                  name: `${requestUser.profile.firstName} ${requestUser.profile.lastName.charAt(0)}.`,
                  profilePicture: requestUser.profile.profilePicture || ''
                };
              } else {
                return { id: requestId, name: 'Unknown User', profilePicture: '' };
              }
            })
          );

          setNotifications(enrichedNotifications);
          setFriends(enrichedFriends);
          setFriendRequests(enrichedFriendRequests);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const handleAcceptFriendRequest = async (notification) => {
    const db = getFirestore();
    const userRef = doc(db, 'users', currentUser.uid);
    const fromUserRef = doc(db, 'users', notification.from);

    await updateDoc(userRef, {
      friends: arrayUnion(notification.from),
      notifications: notifications.filter(notif => notif !== notification),
      receivedRequests: arrayRemove(notification.from)
    });

    await updateDoc(fromUserRef, {
      friends: arrayUnion(currentUser.uid),
      sentRequests: arrayRemove(currentUser.uid)
    });

    setFriends([...friends, { id: notification.from, name: notification.fromName, profilePicture: notification.fromProfilePicture }]);
    setFriendRequests(friendRequests.filter(request => request.id !== notification.from));
  };

  const handleRejectFriendRequest = async (requestId) => {
    const db = getFirestore();
    const userRef = doc(db, 'users', currentUser.uid);
    const fromUserRef = doc(db, 'users', requestId);

    await updateDoc(userRef, {
      receivedRequests: arrayRemove(requestId),
      notifications: notifications.filter(notification => notification.from !== requestId)
    });

    await updateDoc(fromUserRef, {
      sentRequests: arrayRemove(currentUser.uid)
    });

    setFriendRequests(friendRequests.filter(request => request.id !== requestId));
    setNotifications(notifications.filter(notification => notification.from !== requestId));
  };

  const handleRemoveFriend = async (friend) => {
    if (window.confirm(`Are you sure you want to remove ${friend.name} from your friends?`)) {
      const db = getFirestore();
      const userRef = doc(db, 'users', currentUser.uid);
      const friendRef = doc(db, 'users', friend.id);

      await updateDoc(userRef, {
        friends: arrayRemove(friend.id)
      });

      await updateDoc(friendRef, {
        friends: arrayRemove(currentUser.uid)
      });

      setFriends(friends.filter(f => f.id !== friend.id));
    }
  };

  const handleDeleteNotification = async (notification) => {
    const db = getFirestore();
    const userRef = doc(db, 'users', currentUser.uid);

    await updateDoc(userRef, {
      notifications: arrayRemove(notification)
    });

    setNotifications(notifications.filter(notif => notif !== notification));
  };

  return (
    <div className="friends-page">
      <h1>Friends</h1>
      <div className="friends-sections">
        <div className="friends-section">
          <h2>My Friends</h2>
          {friends.length > 0 ? (
            <ul>
              {friends.map((friend, index) => (
                <li key={index}>
                  <Link to={`/user-profile/${friend.id}`}>
                    {friend.profilePicture && <img src={friend.profilePicture} alt={`${friend.name} profile`} className="profile-picture" />}
                    {friend.name}
                  </Link>
                  <button className="remove-button" onClick={() => handleRemoveFriend(friend)}>✖</button>
                </li>
              ))}
            </ul>
          ) : (
            <p>You don&apos;t have any friends yet.</p>
          )}
        </div>
        <div className="friends-section">
          <h2>Friend Requests</h2>
          {friendRequests.length > 0 ? (
            <ul>
              {friendRequests.map((request, index) => (
                <li key={index}>
                  {request.profilePicture && <img src={request.profilePicture} alt={`${request.name} profile`} className="profile-picture" />}
                  {request.name}
                  <button className="accept-button" onClick={() => handleAcceptFriendRequest({ from: request.id, fromName: request.name, fromProfilePicture: request.profilePicture })}>Accept</button>
                  <button className="reject-button" onClick={() => handleRejectFriendRequest(request.id)}>Reject</button>
                </li>
              ))}
            </ul>
          ) : (
            <p>You don&apos;t have any new friend requests.</p>
          )}
        </div>
        <div className="friends-section">
          <h2>Notifications</h2>
          {notifications.length > 0 ? (
            <ul>
              {notifications.map((notification, index) => (
                <li key={index}>
                  {notification.type === 'friend_request' && (
                    <>
                      {notification.fromProfilePicture && <img src={notification.fromProfilePicture} alt={`${notification.fromName} profile`} className="profile-picture" />}
                      <p>You have a new friend request from {notification.fromName}</p>
                      <button className="delete-button" onClick={() => handleDeleteNotification(notification)}>✖</button>
                    </>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>You don&apos;t have any new notifications.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FriendsPage;