import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, query, where, onSnapshot, orderBy, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../firebaseConfig';
import { Link } from 'react-router-dom';
import ReportAbuseModal from './ReportAbuseModal';
import './ChatRoomGroups.css';

function ChatRoomGroups({ chatId, isDisabled }) {  // Ajout de la prop isDisabled
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { currentUser } = useAuth();
  const [userNames, setUserNames] = useState({});
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [reportedUserId, setReportedUserId] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!chatId || !currentUser) return;

    const q = query(
      collection(db, 'messages'),
      where('chatId', '==', chatId),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const msgs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const newSenderIds = msgs
        .map(msg => msg.senderId)
        .filter(senderId => !userNames[senderId]);

      if (newSenderIds.length > 0) {
        const newUserNames = await Promise.all(newSenderIds.map(async (senderId) => {
          const userDoc = await getDoc(doc(db, 'users', senderId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            return { [senderId]: `${userData.profile.firstName} ${userData.profile.lastName.charAt(0)}.` };
          }
          return { [senderId]: 'Unknown User' };
        }));

        setUserNames(prevState => ({
          ...prevState,
          ...Object.assign({}, ...newUserNames)
        }));
      }

      setMessages(msgs);
    });

    return () => unsubscribe();
  }, [chatId, currentUser, userNames]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    await addDoc(collection(db, 'messages'), {
      chatId,
      text: newMessage,
      timestamp: Timestamp.fromDate(new Date()),
      senderId: currentUser.uid
    });

    setNewMessage('');
  };

  const handleReportClick = (userId = null) => {
    setReportedUserId(userId);
    setReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
    setReportedUserId('');
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className={`chat-room ${isDisabled ? 'disabled' : ''}`}> {/* Ajout de la classe "disabled" en fonction de isDisabled */}
      {/* Bouton Report au-dessus des messages pour signaler le groupe */}
      <div className="chat-header">
        <button className="report-button" onClick={() => handleReportClick()} disabled={isDisabled}>Report Group</button>
      </div>

      <div className="messages">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message ${message.senderId === currentUser.uid ? 'sent' : 'received'}`}
          >
            <div className="sender-info">
              {message.senderId === currentUser.uid ? (
                'You'
              ) : (
                <Link to={`/user-profile/${message.senderId}`}>
                  {userNames[message.senderId] || 'Other'}
                </Link>
              )}
            </div>
            <div className="message-info">
              {new Date(message.timestamp.toDate()).toLocaleString()}
            </div>
            <div>{message.text}</div>
            {message.senderId !== currentUser.uid && (
              <button className="small-report-button" onClick={() => handleReportClick(message.senderId)}>
                Report
              </button>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form className="chat-input" onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Write your message..."
          disabled={isDisabled}  // Désactiver l'input de message si le chat est désactivé
        />
        <button type="submit" disabled={isDisabled}>Send</button> {/* Désactiver le bouton d'envoi si le chat est désactivé */}
      </form>

      {isReportModalOpen && (
        <ReportAbuseModal
          onClose={handleCloseReportModal}
          reportedUserId={reportedUserId}
          chatId={chatId}
        />
      )}
    </div>
  );
}

export default ChatRoomGroups;