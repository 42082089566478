import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, onSnapshot, collection, updateDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './MeetingsPage.css';
import { Link } from 'react-router-dom';
import { initiateMeeting } from '../components/initiateMeeting';

function MeetingsPage() {
  const { currentUser } = useAuth();
  const [meetings, setMeetings] = useState([]);
  const [friends, setFriends] = useState([]);
  const [meetPoints, setMeetPoints] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const db = getFirestore();
      const meetingsRef = collection(db, `users/${currentUser.uid}/meetings`);

      const userDocRef = doc(db, 'users', currentUser.uid);
      const removeMeetingNotifications = async () => {
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const notifications = userDoc.data().notifications || [];
          const updatedNotifications = notifications.filter(
            notification => notification.type !== 'meeting_initiated'
          );

          if (notifications.length !== updatedNotifications.length) {
            await updateDoc(userDocRef, {
              notifications: updatedNotifications
            });
          }
        }
      };

      removeMeetingNotifications();

      const unsubscribeMeetings = onSnapshot(meetingsRef, async (snapshot) => {
        const meetingsData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const meeting = docSnapshot.data();
            const otherUserId = meeting.senderId === currentUser.uid ? meeting.receiverId : meeting.senderId;
            const otherUserDoc = await getDoc(doc(getFirestore(), 'users', otherUserId));
            let otherUser = { name: 'Unknown User', profilePicture: '' };
            if (otherUserDoc.exists()) {
              const userData = otherUserDoc.data();
              otherUser = {
                name: `${userData.profile.firstName} ${userData.profile.lastName.charAt(0)}.`,
                profilePicture: userData.profile.profilePicture || '',
              };
            }
            return {
              ...meeting,
              otherUser,
              otherUserId,
            };
          })
        );
        setMeetings(meetingsData);
      });

      const friendsRef = doc(db, 'users', currentUser.uid);
      const unsubscribeFriends = onSnapshot(friendsRef, async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const friendsData = userData.friends || [];
          const enrichedFriends = await Promise.all(
            friendsData.map(async (friendId) => {
              const friendDoc = await getDoc(doc(db, 'users', friendId));
              if (friendDoc.exists()) {
                const friend = friendDoc.data();
                return {
                  id: friendId,
                  name: `${friend.profile.firstName} ${friend.profile.lastName.charAt(0)}.`,
                  profilePicture: friend.profile.profilePicture || ''
                };
              } else {
                return { id: friendId, name: 'Unknown User', profilePicture: '' };
              }
            })
          );
          setFriends(enrichedFriends);
        }
      });

      const fetchMeetPoints = async () => {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const points = userData.meetPoints || 0;
          setMeetPoints(points);
        }
      };

      fetchMeetPoints();

      return () => {
        unsubscribeMeetings();
        unsubscribeFriends();
      };
    }
  }, [currentUser]);

  const handleInitiateMeeting = async (friendId) => {
    try {
      await initiateMeeting(currentUser, friendId);
      alert('Meeting successfully initiated!');
    } catch (error) {
      console.error('Error initiating the meeting:', error);
      alert('An error occurred while initiating the meeting.');
    }
  };

  const handleAcceptMeeting = async (meetingId) => {
    const db = getFirestore();
    const meetingRef = doc(db, `users/${currentUser.uid}/meetings`, meetingId);
    const otherMeetingRef = doc(db, `users/${meetingId.split('_')[0]}/meetings`, meetingId);
    
    await updateDoc(meetingRef, {
      status: 'confirmed',
      confirmedByReceiver: true,
    });
    await updateDoc(otherMeetingRef, {
      status: 'confirmed',
      confirmedByReceiver: true,
    });

    const updateMeetPoints = async (userDocRef) => {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const currentPoints = userDoc.data().meetPoints || 0;
        await updateDoc(userDocRef, {
          meetPoints: currentPoints + 1,
        });
      }
    };

    await updateMeetPoints(doc(db, 'users', currentUser.uid));
    await updateMeetPoints(doc(db, 'users', meetingId.split('_')[0]));

    await updateDoc(meetingRef, {
      meetPointsAwarded: true,
      meetPointsStatus: "awarded",
      ratingPending: true
    });
    await updateDoc(otherMeetingRef, {
      meetPointsAwarded: true,
      meetPointsStatus: "awarded",
      ratingPending: true
    });

    alert('Meeting confirmed! Meetpoints awarded to both users.');

    // Update MeetPoints in real-time
    const userDocRef = doc(db, 'users', currentUser.uid);
    const updatedPoints = await getDoc(userDocRef);
    if (updatedPoints.exists()) {
      setMeetPoints(updatedPoints.data().meetPoints || 0);
    }

    setMeetings((prevMeetings) =>
      prevMeetings.map((meeting) =>
        meeting.meetingId === meetingId ? { ...meeting, status: 'confirmed' } : meeting
      )
    );
  };

  const handleRejectMeeting = async (meetingId) => {
    const db = getFirestore();
    const meetingRef = doc(db, `users/${currentUser.uid}/meetings`, meetingId);
    const otherMeetingRef = doc(db, `users/${meetingId.split('_')[0]}/meetings`, meetingId);

    await updateDoc(meetingRef, {
      status: 'rejected',
    });
    await updateDoc(otherMeetingRef, {
      status: 'rejected',
    });

    setMeetings((prevMeetings) =>
      prevMeetings.map((meeting) =>
        meeting.meetingId === meetingId ? { ...meeting, status: 'rejected' } : meeting
      )
    );
  };

  const getMeetingForFriend = (friendId) => {
    return meetings.find(
      (meeting) =>
        (meeting.senderId === currentUser.uid && meeting.receiverId === friendId) ||
        (meeting.receiverId === currentUser.uid && meeting.senderId === friendId)
    );
  };

  return (
    <div className="meetings-page">
      <h1>Meetings</h1>
      <div className="friends-section">
        <h2>My Friends</h2>
        {friends.length > 0 ? (
          <ul>
            {friends.map((friend, index) => {
              const meeting = getMeetingForFriend(friend.id);
              return (
                <li key={index} className="meeting-item">
                  <Link to={`/user-profile/${friend.id}`}>
                    {friend.profilePicture && (
                      <img src={friend.profilePicture} alt={`${friend.name} profile`} className="profile-picture" />
                    )}
                    {friend.name}
                  </Link>
                  <div className="meeting-actions">
                    {!meeting && (
                      <button
                        className="initiate-button"
                        onClick={() => handleInitiateMeeting(friend.id)}
                      >
                        Initiate a Meeting
                      </button>
                    )}
                    {meeting && meeting.status === 'pending' && meeting.senderId === currentUser.uid && (
                      <button className="action-button" disabled>
                        Meeting Pending Validation
                      </button>
                    )}
                    {meeting && meeting.status === 'pending' && meeting.receiverId === currentUser.uid && (
                      <>
                        <p>{`This user wants to validate the meeting with you: ${friend.name}`}</p>
                        <div className="meeting-actions">
                          <button
                            className="accept-button action-button"
                            onClick={() => handleAcceptMeeting(meeting.meetingId)}
                          >
                            Accept
                          </button>
                          <button
                            className="reject-button action-button"
                            onClick={() => handleRejectMeeting(meeting.meetingId)}
                          >
                            Reject
                          </button>
                        </div>
                      </>
                    )}
                    {meeting && meeting.status === 'confirmed' && (
                      <button className="action-button" disabled>
                        Meeting Confirmed
                      </button>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>You don&apos;t have any friends yet.</p>
        )}
      </div>

      {/* Meetpoints Section */}
      <div className="meetpoints-section">
        <h2>My Meetpoints</h2>
        <div className="meetpoints-content">
          <i className="fas fa-star meetpoints-icon"></i> {/* Star icon for Meetpoints */}
          <p>{`You have ${meetPoints} Meetpoints.`}</p> {/* Displays the number of Meetpoints */}
        </div>
      </div>
    </div>
  );
}

export default MeetingsPage;