// src/Pages/LegalMentions.js
import React from 'react';
import './LegalMentions.css'; // Import du CSS

function LegalMentions() {
  return (
    <div className="legal-mentions-container">
      <h2>Legal Notices</h2>

      {/* Section 1: Legal Information */}
      <section>
        <h3>1. Legal Information</h3>
        <p>
          Publisher of the website and application:
          <br />
          Meevel SAS
          <br />
          Simplified Joint Stock Company (SAS) with a capital of €100.00
          <br />
          Headquarters: 10 Avenue du Stand, 74000 Annecy, France
          <br />
          Registered with the Annecy Trade and Companies Register under number 932 318 504
          <br />
          Management number: 2024B01880
          <br />
          President: Charly ROMERA, born on 23/07/1993 in Carcassonne (11), French nationality
          <br />
          Managing Director: Pomeline CAMPAGNE, born on 29/04/1993 in Clermont-Ferrand (63), French nationality
          <br />
          Contact Email: support@meevel.app
          <br />
          Website domain name: meevel.app
        </p>
      </section>

      {/* Section 2: Hosting */}
      <section>
        <h3>2. Hosting</h3>
        <p>
          The site and application are hosted by:
          <br />
          [Name of the host]
          <br />
          Address: [Host’s address]
          <br />
          Contact: [Host’s email]
        </p>
      </section>

      {/* Section 3: Intellectual Property */}
      <section>
        <h3>3. Intellectual Property</h3>
        <p>
          The Meevel app, available on the App Store and Google Play Store, as well as the meevel.app website and all its components (texts, images, logos, videos, etc.) are the exclusive property of Meevel SAS or its partners. Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the application or website is prohibited, unless prior written authorization is obtained from Meevel SAS.
        </p>
        <p>
        &quotMeevel&quot is a registered trademark with the French National Institute of Industrial Property (INPI). Any unauthorized use of the Meevel trademark or any content on this site is strictly prohibited and may constitute a violation of intellectual property rights.
        </p>
      </section>

      {/* Section 4: Personal Data Protection */}
      <section>
        <h3>4. Personal Data Protection</h3>
        <p>
          Meevel is committed to protecting the privacy of its users in accordance with the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and other applicable local data protection laws.
        </p>
        <p>For more information, please see our Privacy Policy.</p>
      </section>

      {/* Section 5: Limitation of Liability */}
      <section>
        <h3>5. Limitation of Liability</h3>
        <p>
          Meevel makes every effort to provide accurate and up-to-date information on its application and website. However, Meevel cannot be held responsible for omissions, inaccuracies, or errors in the information provided. The use of information and services offered by Meevel is the sole responsibility of the user.
        </p>
      </section>

      {/* Section 6: Hyperlinks */}
      <section>
        <h3>6. Hyperlinks</h3>
        <p>
          The site and the application may contain hyperlinks to other sites. Meevel has no control over these sites and cannot be held responsible for their content or privacy practices.
        </p>
      </section>

      {/* Section 7: Applicable Law and Jurisdiction */}
      <section>
        <h3>7. Applicable Law and Jurisdiction</h3>
        <p>
          These legal notices are governed by French law. In the event of a dispute, and in the absence of an amicable resolution, the courts of Annecy shall have jurisdiction to settle the dispute.
        </p>
      </section>

      {/* Section 8: Meevel Mobile Application */}
      <section>
        <h3>8. Meevel Mobile Application</h3>
        <p>
          The Meevel mobile application is available for free download from the App Store (for iOS devices) and Google Play Store (for Android devices). Users must accept the Meevel terms of use and privacy policy to use the application.
        </p>
      </section>

      {/* Section 9: Modification of Legal Notices */}
      <section>
        <h3>9. Modification of Legal Notices</h3>
        <p>
          Meevel reserves the right to modify these legal notices at any time. Users will be informed of any changes via the application or website.
        </p>
      </section>
    </div>
  );
}

export default LegalMentions;