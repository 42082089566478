import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Accueil from './Pages/Accueil';
import ProfilePage from './Pages/ProfilePage';
import UserMapPage from './Pages/UserMapPage';
import ChatPage from './Pages/ChatPage';
import ConversationList from './components/ConversationList';
import CountryListPage from './Pages/CountryListPage';
import AccountPage from './components/AccountPage';
import IdentityVerificationPage from './Pages/IdentityVerificationPage';
import UserProfilePublic from './components/UserProfilePublic';
import Footer from './components/Footer';
import ProfileForm from './components/ProfileForm';
import FriendsPage from './Pages/FriendsPage';
import MeetingsPage from './Pages/MeetingsPage';
import SettingsPage from './Pages/SettingsPage';
import RatingPage from './Pages/RatingPage';
import FavoriteGroupsPage from './Pages/FavoriteGroupsPage';
import CountryCityList from './components/CountryCityList';
import LoginEmail from './Pages/LoginEmail';
import SignUpEmail from './Pages/SignUpEmail';
import EmailVerificationPage from './Pages/EmailVerificationPage';
import LegalMentions from './Pages/LegalMentions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAcceptance from './Pages/TermsAcceptance';
import { getFirestore, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useAuth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig'; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import usersData from './components/users.json';

function App() {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { currentUser } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    setUsers(usersData);
  }, []);

  useEffect(() => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);

      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setNotifications(userData.notifications || []);
          setIsProfileComplete(userData.isProfileComplete || false);
          setTermsAccepted(userData.termsAccepted || false);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        if (user.emailVerified) {
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, { verified: true });
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [db]);

  // Utiliser useLocation à l'intérieur du composant
  const location = useLocation(); 

  // Calculer la visibilité du footer
  const shouldShowFooter = location.pathname !== '/' && user && termsAccepted && isProfileComplete;

  // Insérer le script Google AdSense
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    script.async = true;
    script.crossOrigin = 'anonymous';
    script.setAttribute('data-ad-client', 'ca-pub-2025341995771864');
    document.body.appendChild(script);

    return () => {
      // Supprimer le script lorsque le composant est démonté
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={!user ? <HomePage setUser={setUser} /> : <Accueil user={user} />} />
        <Route path="/login" element={<HomePage setUser={setUser} />} />
        <Route path="/login-email" element={<LoginEmail setUser={setUser} />} />
        <Route path="/signup-email" element={<SignUpEmail setUser={setUser} />} />
        <Route path="/email-verification" element={<EmailVerificationPage />} />
        <Route path="/identity-verification" element={<IdentityVerificationPage setUser={setUser} />} />
        <Route path="/profile" element={<ProfilePage user={user} setUser={setUser} />} />
        <Route path="/profile-form" element={<ProfileForm />} />
        <Route path="/friends" element={<FriendsPage notifications={notifications} setNotifications={setNotifications} />} />
        <Route path="/meetings" element={<MeetingsPage />} />
        <Route path="/ratings" element={<RatingPage />} />
        <Route path="/settings" element={<SettingsPage setUser={setUser} />} />
        <Route path="/user-map" element={<UserMapPage users={users} />} />
        <Route path="/conversations" element={<ConversationList />} />
        <Route path="/chat/:conversationId" element={<ChatPage user={user} />} />
        <Route path="/chat" element={<ConversationList />} />
        <Route path="/country-list" element={<CountryListPage />} />
        <Route path="/countrycitylist" element={<CountryCityList />} />
        <Route path="/account" element={<AccountPage user={user} setUser={setUser} />} />
        <Route path="/user-profile/:userId" element={<UserProfilePublic />} />
        <Route path="/favorite-groups" element={<FavoriteGroupsPage />} />
        <Route path="/settings/legal-mentions" element={<LegalMentions />} />
        <Route path="/settings/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-acceptance" element={<TermsAcceptance />} />
        <Route path="/accueil" element={<Accueil user={user} />} /> 
      </Routes>
      {shouldShowFooter && <Footer notifications={notifications} />}
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}