import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../firebaseConfig';
import './CountryCityList.css';
import ChatRoomGroups from './ChatRoomGroups';
import Tutorial from './Tutorial';

function CountryCityList() {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [chatGroups, setChatGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [favoriteGroups, setFavoriteGroups] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const groupTranslationDictionary = {
    "Gastronomie": "Gastronomy",
    "Hébergement": "Accommodation",
    "Rencontres de groupe": "Group Meetings",
    "Sorties et Loisirs": "Outings and Leisure",
    "Sécurité et Urgences": "Security and Emergencies",
    "Transports et Mobilité": "Transport and Mobility",
    "Travailleurs Nomades": "Nomadic Workers"
  };

  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("There was an error fetching the countries!", error);
      }
    }

    fetchCountries();
  }, []);

  useEffect(() => {
    const savedCountry = localStorage.getItem('selectedCountry');
    const savedCity = localStorage.getItem('selectedCity');
    const savedGroup = localStorage.getItem('selectedGroup');

    if (savedCountry) {
      setSelectedCountry(savedCountry);
    }
    if (savedCity) {
      try {
        setSelectedCity(JSON.parse(savedCity));
      } catch (error) {
        setSelectedCity({ name: savedCity });
      }
    }
    if (savedGroup) {
      try {
        setSelectedGroup(JSON.parse(savedGroup));
      } catch (error) {
        console.error('Failed to parse saved group from localStorage:', error);
      }
    }

    const countryParam = searchParams.get('country');
    const cityParam = searchParams.get('city');
    const groupParam = searchParams.get('chatId');

    if (countryParam) {
      setSelectedCountry(countryParam);
    }
    if (cityParam) {
      setSelectedCity({ name: cityParam });
    }
    if (groupParam) {
      setSelectedGroup({ chat_id: groupParam });
    }
  }, [searchParams]);

  useEffect(() => {
    async function fetchCities() {
      if (selectedCountry) {
        try {
          const citiesCollection = collection(db, `cities_by_country_${selectedCountry}`);
          const citiesSnapshot = await getDocs(citiesCollection);
          const citiesData = citiesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            name: doc.data().name || doc.id 
          }));
          setCities(citiesData);
        } catch (error) {
          console.error("There was an error fetching the cities!", error);
        }
      }
    }

    fetchCities();
  }, [selectedCountry]);

  useEffect(() => {
    async function fetchChatGroups() {
      if (selectedCity) {
        try {
          const cityRef = doc(db, `cities_by_country_${selectedCountry}`, selectedCity.name);
          const groupsSnapshot = await getDocs(collection(cityRef, 'groups'));
          const groupsData = groupsSnapshot.docs.map(doc => {
            const groupData = doc.data();
            return {
              ...groupData,
              name: groupTranslationDictionary[groupData.name] || groupData.name
            };
          });
          setChatGroups(groupsData);

          if (selectedGroup && selectedGroup.chat_id) {
            const group = groupsData.find(group => group.chat_id === selectedGroup.chat_id);
            setSelectedGroup(group);
          }
        } catch (error) {
          console.error("There was an error fetching the chat groups!", error);
        }
      }
    }

    fetchChatGroups();
  }, [selectedCity, selectedCountry]);

  useEffect(() => {
    async function fetchFavorites() {
      if (currentUser) {
        try {
          const favoritesCollection = collection(db, `users/${currentUser.uid}/favorites`);
          const favoritesSnapshot = await getDocs(favoritesCollection);
          const favoritesData = favoritesSnapshot.docs.map(doc => doc.data());
          setFavoriteGroups(favoritesData.map(fav => fav.chat_id));
        } catch (error) {
          console.error("There was an error fetching the favorite groups!", error);
        }
      }
    }

    fetchFavorites();
  }, [currentUser]);

  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption ? selectedOption.value : '';
    setSelectedCountry(selectedCountry);
    setSelectedCity(null);
    setChatGroups([]);
    setSelectedGroup(null);

    localStorage.setItem('selectedCountry', selectedCountry);
    localStorage.removeItem('selectedCity');
    localStorage.removeItem('selectedGroup');

    navigate(`/countrycitylist?country=${selectedCountry}`);
  };

  const handleCityChange = (event) => {
    const cityName = event.target.value;
    const city = cities.find(city => city.name === cityName);
    setSelectedCity(city);
    setSelectedGroup(null);

    localStorage.setItem('selectedCity', JSON.stringify(city));
    localStorage.removeItem('selectedGroup');

    navigate(`/countrycitylist?country=${selectedCountry}&city=${city.name}`);
  };

  const handleGroupChange = (event) => {
    const groupName = event.target.value;
    const group = chatGroups.find(group => group.name === groupName);
    setSelectedGroup(group);

    localStorage.setItem('selectedGroup', JSON.stringify(group));

    navigate(`/countrycitylist?country=${selectedCountry}&city=${selectedCity?.name}&chatId=${group.chat_id}`);
  };

  const toggleFavoriteGroup = async (group) => {
    if (!currentUser) return;

    const groupRef = doc(db, `users/${currentUser.uid}/favorites`, group.chat_id);

    if (favoriteGroups.includes(group.chat_id)) {
      await deleteDoc(groupRef);
      setFavoriteGroups(favoriteGroups.filter(fav => fav !== group.chat_id));
    } else {
      await setDoc(groupRef, {
        chat_id: group.chat_id,
        groupName: group.name,
        city: selectedCity.name,
        country: selectedCountry
      });
      setFavoriteGroups([...favoriteGroups, group.chat_id]);
    }
  };

  const isGroupFavorited = selectedGroup && favoriteGroups.includes(selectedGroup.chat_id);

  const countryOptions = useMemo(() => {
    return countries.map(country => ({
      value: country.cca2,
      label: (
        <div className="country-option">
          <img
            src={`https://flagcdn.com/w20/${country.cca2.toLowerCase()}.png`}
            alt={country.name.common}
            className="flag-icon"
          />
          {country.name.common}
        </div>
      )
    }));
  }, [countries]);

  useEffect(() => {
    // Reset scroll position to top when the component mounts or when nothing is selected
    window.scrollTo(0, 0);
  }, [selectedCountry, selectedCity, selectedGroup]);

  return (
    <div className="country-city-list-container">
      <h2 className="country-list-page-subtitle">Forum groups</h2>
      {/* Conteneur pour les sélections de pays, villes et groupes */}
      <div className="country-selection-container">
        <h2>Countries and Major Cities List</h2>
        <div className="form-group">
          <label htmlFor="country">Select a Country</label>
          <Select
            id="country"
            options={countryOptions}
            onChange={handleCountryChange}
            isClearable
            value={countryOptions.find(option => option.value === selectedCountry)}
            placeholder="Search for a country..."
            filterOption={(option, inputValue) => 
              option.label.props.children[1].toLowerCase().includes(inputValue.toLowerCase())
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">Select a City</label>
          <select 
            id="city" 
            className="form-control" 
            onChange={handleCityChange} 
            value={selectedCity?.name || ''} 
            disabled={!selectedCountry}  
          >
            <option value="">Choose a city</option>
            {cities.map(city => (
              <option key={city.id} value={city.name}>{city.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="group">Select a Chat Group</label>
          <select 
            id="group" 
            className="form-control" 
            onChange={handleGroupChange} 
            value={selectedGroup?.name || ''} 
            disabled={!selectedCity}  // Désactiver jusqu'à ce qu'une ville soit sélectionnée
          >
            <option value="">Choose a group</option>
            {chatGroups.map(group => (
              <option key={group.chat_id} value={group.name}>{group.name}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Conteneur pour la fenêtre de chat */}
      <div className="chat-room-container">
        <h3>
          {selectedGroup && selectedCity 
            ? `${selectedGroup.name} in ${selectedCity.name}`
            : 'Chat Room'}
          {' '}
        </h3>
        <div className="chat-controls">
          <button
            className={`favorite-button ${isGroupFavorited ? 'favorited' : ''}`}
            onClick={() => toggleFavoriteGroup(selectedGroup)}
            aria-label={isGroupFavorited ? 'Remove from favorites' : 'Add to favorites'}
            disabled={!selectedGroup}  // Désactiver le bouton jusqu'à ce qu'un groupe soit sélectionné
          >
            {isGroupFavorited ? '⭐ Remove from favorites' : '☆ Add to favorites'}
          </button>
        </div>
        
        {/* Composant ChatRoomGroups avec la prop isDisabled */}
        <ChatRoomGroups chatId={selectedGroup?.chat_id} isDisabled={!selectedGroup} />
      </div>

      {/* Composant Tutorial en bas de page */}
      <Tutorial
        selectedCountry={selectedCountry}
        selectedCity={selectedCity}
        selectedGroup={selectedGroup}
      />
    </div>
  );
}

export default CountryCityList;