// src/Pages/Accueil.js
import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, query, where, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import Footer from '../components/Footer';
import Header from '../components/Header';
import GeolocationModal from '../components/GeolocationModal';
import { Link } from 'react-router-dom';
import { FaUtensils, FaBed, FaUsers, FaHiking, FaShieldAlt, FaBus, FaLaptop } from 'react-icons/fa';
import Tutorial from '../components/Tutorial';
import WelcomeMessage from '../components/WelcomeMessage'; // Import du composant WelcomeMessage
import './Accueil.css';

const countryCodes = [
  // Liste des codes de pays
  'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ',
  'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR',
  'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM',
  'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ',
  'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB',
  'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU',
  'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IQ', 'IR', 'IS',
  'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY',
  'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD',
  'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU',
  'MV', 'MW', 'MX', 'MY', 'MZ', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU',
  'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW',
  'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI',
  'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD',
  'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA',
  'UG', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE',
  'YT', 'ZA', 'ZM', 'ZW'
];

// Fonction pour calculer la distance entre deux coordonnées géographiques
const haversineDistance = (coords1, coords2) => {
  const toRad = (x) => (x * Math.PI) / 180;
  const R = 6371; // Rayon de la Terre en km

  const dLat = toRad(coords2.lat - coords1.lat);
  const dLon = toRad(coords2.lng - coords1.lng);
  const lat1 = toRad(coords1.lat);
  const lat2 = toRad(coords2.lat);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

// Fonction pour obtenir l'icône du groupe
const getGroupIcon = (groupName) => {
  switch (groupName) {
    case 'Gastronomie': return <FaUtensils />;
    case 'Hébergement': return <FaBed />;
    case 'Rencontres de groupe': return <FaUsers />;
    case 'Sorties et Loisirs': return <FaHiking />;
    case 'Sécurité et Urgences': return <FaShieldAlt />;
    case 'Transports et Mobilité': return <FaBus />;
    case 'Travailleurs Nomades': return <FaLaptop />;
    default: return <FaUsers />;
  }
};

// Fonction pour traduire le nom du groupe
const translateGroupName = (name) => {
  const translations = {
    'Gastronomie': 'Gastronomy',
    'Hébergement': 'Accommodation',
    'Rencontres de groupe': 'Group Meetings',
    'Sorties et Loisirs': 'Outings and Leisure',
    'Sécurité et Urgences': 'Security and Emergencies',
    'Transports et Mobilité': 'Transport and Mobility',
    'Travailleurs Nomades': 'Nomadic Workers',
  };
  return translations[name] || name;
};

// Fonction pour obtenir le feedback positif
const getPositiveFeedback = (user) => {
  if (user.receivedEvaluations && user.receivedEvaluations.length > 0) {
    const positiveFeedbacks = user.receivedEvaluations.flatMap((evaluation) => evaluation.positiveFeedback || []);
    if (positiveFeedbacks.length > 0) return positiveFeedbacks[0];
  }
  return null;
};

// Fonction pour formater le dernier login
const formatLastLogin = (lastLogin) => {
  if (!lastLogin || !lastLogin.seconds) return 'N/A';
  const date = new Date(lastLogin.seconds * 1000);
  return date.toLocaleString();
};

const Accueil = ({ user }) => {
  const { currentUser } = useAuth();
  const [nearbyUsers, setNearbyUsers] = useState([]);
  const [activeGroups, setActiveGroups] = useState([]);
  const [initialLocation, setInitialLocation] = useState(null);
  const [selectedCityName, setSelectedCityName] = useState('No location set');
  const [isGeolocationModalOpen, setGeolocationModalOpen] = useState(false);
  const [partners, setPartners] = useState([]);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false); // État pour le message de bienvenue
  const groupsPerPage = 10;

  useEffect(() => {
    // Vérifier si c'est la première connexion après le remplissage du profil
    const isFirstLogin = localStorage.getItem('firstLogin');
    if (!isFirstLogin) {
      setShowWelcomeMessage(true);
      localStorage.setItem('firstLogin', 'true'); // Marquer que l'utilisateur a vu le message
    }
  }, []);

  const handleCloseWelcomeMessage = () => {
    setShowWelcomeMessage(false);
  };

  const fetchUserLocationFromFirestore = async () => {
    if (currentUser) {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.lat && userData.lng) {
          setInitialLocation({ lat: userData.lat, lng: userData.lng });
          setSelectedCityName(userData.cityName || 'No location set');
        } else {
          handleUseCurrentLocation();
        }
      }
    }
  };

  useEffect(() => {
    fetchUserLocationFromFirestore();
  }, [currentUser]);

  useEffect(() => {
    const fetchNearbyUsers = async () => {
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, 'users'));

      const allUsers = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const evaluationsSnapshot = await getDocs(collection(db, `users/${doc.id}/receivedEvaluations`));
          data.receivedEvaluations = evaluationsSnapshot.docs.map((evalDoc) => evalDoc.data());
          return data;
        })
      );

      if (initialLocation) {
        const nearby = allUsers
          .filter(u => u.id !== currentUser?.uid && u.lat && u.lng)
          .map(u => ({
            ...u,
            distance: haversineDistance(initialLocation, { lat: u.lat, lng: u.lng })
          }))
          .sort((a, b) => a.distance - b.distance);
  
        setNearbyUsers(nearby);
      }
    };

    const fetchActiveGroups = async () => {
      const cachedLocation = JSON.parse(localStorage.getItem('cachedLocation'));
      const cachedGroups = JSON.parse(localStorage.getItem('cachedGroups'));

      if (cachedLocation && cachedGroups && haversineDistance(initialLocation, cachedLocation) < 1) {
        setActiveGroups(cachedGroups);
        return;
      }

      const db = getFirestore();
      let allGroups = [];

      for (const countryCode of countryCodes) {
        const cityCollectionRef = collection(db, `cities_by_country_${countryCode}`);
        const cityDocs = await getDocs(cityCollectionRef);

        for (const cityDoc of cityDocs.docs) {
          const cityData = cityDoc.data();

          if (cityData && cityData.lat && cityData.lng) {
            const distance = haversineDistance(initialLocation, { lat: cityData.lat, lng: cityData.lng });

            if (distance <= 50) {
              const groupsSnapshot = await getDocs(collection(cityDoc.ref, 'groups'));
              const groups = await Promise.all(groupsSnapshot.docs.map(async doc => {
                const groupData = {
                  id: doc.id,
                  ...doc.data(),
                  cityLat: cityData.lat,
                  cityLng: cityData.lng,
                  cityName: cityData.name,
                  country: cityData.country,
                  distance: distance,
                };

                const messagesCollection = collection(db, 'messages');
                const messagesQuery = query(messagesCollection, where('chatId', '==', groupData.chat_id));
                const messagesSnapshot = await getDocs(messagesQuery);

                groupData.messageCount = messagesSnapshot.docs.length;
                groupData.name = translateGroupName(groupData.name);

                return groupData;
              }));

              allGroups = allGroups.concat(groups);
            }
          }
        }
      }

      allGroups.sort((a, b) => a.distance - b.distance);
      setActiveGroups(allGroups.slice(0, 50));

      localStorage.setItem('cachedGroups', JSON.stringify(allGroups.slice(0, 50)));
      localStorage.setItem('cachedLocation', JSON.stringify(initialLocation));
    };

    const fetchLocalPartners = async () => {
      const db = getFirestore();
      const partnersRef = collection(db, 'partners');
      const partnersSnapshot = await getDocs(partnersRef);
      const allPartners = partnersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const localPartners = allPartners.filter(partner => {
        const distance = haversineDistance(initialLocation, { lat: partner.latitude, lng: partner.longitude });
        return distance <= 50; // Filtre les partenaires à 50 km ou moins
      });

      setPartners(localPartners);
    };

    if (initialLocation) {
      fetchNearbyUsers();
      fetchActiveGroups();
      fetchLocalPartners();
    }
  }, [currentUser, initialLocation]);

  const handleSetLocation = (lat, lon, cityName) => {
    if (lat && lon && cityName) {
      setInitialLocation({ lat, lng: lon });
      setSelectedCityName(cityName);

      const db = getFirestore();
      const userDocRef = doc(db, 'users', currentUser.uid);
      updateDoc(userDocRef, {
        lat: lat,
        lng: lon,
        cityName: cityName,
      })
        .then(() => {
          alert('Location updated successfully!');
        })
        .catch((error) => {
          console.error('Error updating location: ', error);
          alert('Failed to update location.');
        });
    } else {
      alert('City not found. Please enter a valid city name.');
    }
  };

  const handleUseCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setInitialLocation({ lat: latitude, lng: longitude });

        const db = getFirestore();
        const userDocRef = doc(db, 'users', currentUser.uid);

        updateDoc(userDocRef, {
          lat: latitude,
          lng: longitude,
          cityName: 'Current Location',
        })
          .then(() => {
            setSelectedCityName('Current Location');
            alert('Location updated to current position successfully!');
          })
          .catch((error) => {
            console.error('Error updating location: ', error);
            alert('Failed to update location.');
          });
      },
      (error) => {
        console.error('Error fetching current location: ', error);
        alert('Failed to fetch current location.');
      }
    );
  };

  const handleOpenGeolocationModal = () => {
    setGeolocationModalOpen(true);
  };

  const handleCloseGeolocationModal = () => {
    setGeolocationModalOpen(false);
  };

  const handleGroupClick = (group) => {
    const { chat_id, country, cityName, name } = group;

    localStorage.setItem('selectedCountry', country);
    localStorage.setItem('selectedCity', JSON.stringify({ name: cityName }));
    localStorage.setItem('selectedGroup', JSON.stringify({ chat_id, name }));

    window.location.href = '/countrycitylist';
  };

  return (
    <div className="accueil-page">
      <Header
        user={user}
        selectedCityName={selectedCityName}
        onSetLocation={handleSetLocation}
        onUseCurrentLocation={handleUseCurrentLocation}
        onOpenGeolocationModal={handleOpenGeolocationModal}
        className="header-location"
      />

      {isGeolocationModalOpen && (
        <GeolocationModal
          onClose={handleCloseGeolocationModal}
          onSetLocation={handleSetLocation}
          onUseCurrentLocation={handleUseCurrentLocation}
        />
      )}

      {showWelcomeMessage && (
        <WelcomeMessage onClose={handleCloseWelcomeMessage} />
      )}

      <main className="main-content">
        <section className="nearby-users-section">
          <h3>Users Nearby</h3>
          <div className="nearby-users">
            {nearbyUsers.length > 0 ? (
              nearbyUsers.map((user, index) => (
                <React.Fragment key={user.id}>
                  {index > 0 && index % 5 === 0 && (
                    <div className="adsense">
                      <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-2025341995771864"
                        data-ad-slot="5536568259"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                      <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                      </script>
                    </div>
                  )}
                  <div className="user-item">
                    <div className="user-info">
                      <Link to={`/user-profile/${user.id}`}>
                        <img
                          src={user.profile?.profilePicture || 'path_to_default_image.jpg'}
                          alt="Profile"
                          className="user-profile-image"
                        />
                      </Link>
                      <div className="user-basic-info">
                        <h5>{user.profile?.firstName} {user.profile?.lastName.charAt(0)}.</h5>
                        <p>Distance: {user.distance ? `${user.distance.toFixed(1)} km` : 'N/A'}</p>
                        <p>Age: {user.profile?.age ?? 'N/A'}</p>
                        <p>Nationality: {user.profile?.nationality ?? 'N/A'}</p>
                      </div>
                    </div>
                    <div className="user-details">
                      <div className="status-indicator">
                        <span className={`status-dot ${user.onlineStatus === "online" ? "online" : "offline"}`}></span>
                        <span className="status-text">
                          {user.onlineStatus === "online"
                            ? "Connected"
                            : `Offline from ${formatLastLogin(user.lastLogin)}`}
                        </span>
                      </div>

                      {getPositiveFeedback(user) && (
                        <div className="feedback-badge">
                          {getPositiveFeedback(user)}
                        </div>
                      )}

                      {user.meetPoints && (
                        <div className="meetpoints-badge">
                          <span className="star-icon">★</span>
                          <span className="meetpoints-number">{user.meetPoints}</span> Meetpoints
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <p>No users nearby found.</p>
            )}
          </div>
        </section>
  
          <section className="groups-section groups-nearby">
            <h3>Active Groups Nearby</h3>
            <div className="active-groups">
              {activeGroups.length > 0 ? (
                activeGroups.slice(0, groupsPerPage).map((group, index) => (
                  <React.Fragment key={group.id}>
                    {index > 0 && index % 5 === 0 && (
                      // Insertion d'une publicité tous les 5 groupes
                      <div className="adsense">
                        <ins className="adsbygoogle"
                          style={{ display: 'block' }}
                        data-ad-client="ca-pub-2025341995771864"
                        data-ad-slot="5536568259"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                      <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                      </script>
                    </div>
                  )}
                  <div 
                    className="group-item"
                    role="button" 
                    tabIndex={0} 
                    onClick={() => handleGroupClick(group)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleGroupClick(group);
                      }
                    }}
                  >
                    <div className="group-info">
                      <div className="group-icon">
                        {getGroupIcon(group.name)}
                      </div>
                      <div className="group-details">
                        <h5>{translateGroupName(group.name)}</h5>
                        <p>City: {group.cityName}, {group.country}</p>
                        <p>Distance: {group.distance ? `${group.distance.toFixed(1)} km` : 'N/A'}</p>
                        <p>Messages: {group.messageCount ?? '0'}</p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <p>No active groups found.</p>
            )}
          </div>
          {activeGroups.length > groupsPerPage && (
            <div className="group-slider-controls">
              <button
                onClick={() => setActiveGroups(prevGroups => [...prevGroups.slice(groupsPerPage)])}
                disabled={activeGroups.length <= groupsPerPage}
              >
                Load more groups
              </button>
            </div>
          )}
        </section>

        <section className="advertisements-section">
          <h3>Advertisements</h3>
          <div className="advertisements">
            {partners.length > 0 ? (
              partners.map((partner, index) => (
                <React.Fragment key={partner.id}>
                  {index > 0 && index % 5 === 0 && (
                    // Insertion d'une publicité tous les 5 partenaires
                    <div className="adsense">
                      <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-2025341995771864"
                        data-ad-slot="5536568259"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                      <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                      </script>
                    </div>
                  )}
                  <div className="advertisement-item">
                    {/* Titre de la publicité au-dessus de l'image */}
                    <h4>{partner.adTitle}</h4>

                    {/* Lien vers l'image de la publicité */}
                    <a href={partner.adUrl} target="_blank" rel="noopener noreferrer">
                      <img
                        src={partner.adImageUrl}
                        alt={partner.adTitle}
                        className="advertisement-image"
                      />
                    </a>

                    {/* Informations sur le partenaire en dessous de l'image */}
                    <div className="advertisement-details">
                      <p className="partner-info">
                        {partner.name} - {partner.type}
                      </p>
                      <p className="partner-address">{partner.address}</p>
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div>
                <p>No local advertisements found. Showing default ads.</p>
                {/* Emplacement de l'annonce Google AdSense */}
                <div className="adsense">
                  <ins className="adsbygoogle"
                       style={{ display: 'block' }}
                       data-ad-client="ca-pub-2025341995771864"
                       data-ad-slot="5536568259"
                       data-ad-format="auto"
                       data-full-width-responsive="true"></ins>
                  <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                </div>
              </div>
            )}
          </div>
        </section>

        <Tutorial />
      </main>

      <Footer />
    </div>
  );
};

export default Accueil;