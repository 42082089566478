import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';
import './Footer.css';

const Footer = () => {
  const { currentUser } = useAuth();
  const [friendNotificationCount, setFriendNotificationCount] = useState(0);
  const [meetingNotificationCount, setMeetingNotificationCount] = useState(0);
  const [ratingNotificationCount, setRatingNotificationCount] = useState(0);
  const [messageNotificationCount, setMessageNotificationCount] = useState(0);
  const db = getFirestore();
  const location = useLocation(); // Utilisé pour savoir quand on est sur un onglet particulier

  useEffect(() => {
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);

    const fetchAndUpdateNotifications = async () => {
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const notifications = userDoc.data().notifications || [];

        // Filtrer les notifications pour les demandes d'amis, les rencontres, et les évaluations
        const pendingFriendNotifications = notifications.filter(notification => notification.type === 'friend_request');
        let pendingMeetingNotifications = notifications.filter(notification => notification.type === 'meeting_initiated');
        let pendingRatingNotifications = notifications.filter(notification => notification.type === 'rating_pending');

        // Si l'utilisateur est sur l'onglet Rencontres, on ne supprime que les notifications de rencontres
        if (location.pathname === '/meetings') {
          pendingMeetingNotifications = [];
        }

        // Si l'utilisateur est sur l'onglet Évaluations, on ne supprime que les notifications d'évaluations
        if (location.pathname === '/ratings') {
          pendingRatingNotifications = [];
        }

        // Mettre à jour le nombre de notifications
        setFriendNotificationCount(pendingFriendNotifications.length);
        setMeetingNotificationCount(pendingMeetingNotifications.length);
        setRatingNotificationCount(pendingRatingNotifications.length);
      }
    };

    // Écoute en temps réel pour les notifications de messages
    const fetchMessageNotifications = () => {
      const q = query(
        collection(db, 'chats'),
        where('members', 'array-contains', currentUser.uid)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let unreadCount = 0;
        querySnapshot.forEach(docSnapshot => {
          const data = docSnapshot.data();
          const lastRead = data.readBy?.[currentUser.uid];
          
          // Compte les messages non lus uniquement si la date du dernier message est plus récente que la date du dernier message lu
          if (!lastRead || data.lastMessage?.timestamp > lastRead) {
            unreadCount++;
          }
        });

        setMessageNotificationCount(unreadCount);
      });

      return unsubscribe;
    };

    fetchAndUpdateNotifications();
    const unsubscribeMessages = fetchMessageNotifications();

    return () => {
      if (unsubscribeMessages) unsubscribeMessages();
    };
  }, [currentUser, db, location.pathname]); // location.pathname ajouté pour réagir au changement d'onglet

  // Calculer le nombre total de notifications (amis + rencontres + évaluations)
  const totalProfileNotifications = friendNotificationCount + meetingNotificationCount + ratingNotificationCount;

  return (
    <footer className="footer-wrapper">
      <div className="footer-item">
        <Link to="/">
          <i className="fas fa-home"></i>
          <span>Home</span>
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/country-list">
          <i className="fas fa-users"></i>
          <span>Groups</span>
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/user-map">
          <i className="fas fa-search-location"></i>
          <span>User map</span>
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/chat">
          <i className="fas fa-comments"></i>
          <span>Messages</span>
          {messageNotificationCount > 0 && (
            <span className="notification-count">{messageNotificationCount}</span>
          )}
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/profile">
          <i className="fas fa-user"></i>
          <span>Profile</span>
          {totalProfileNotifications > 0 && (
            <span className="notification-count">{totalProfileNotifications}</span>
          )}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;