import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserFriends, faHeart, faStar, faCog, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { getFirestore, doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../firebaseConfig';

function ProfilePage() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [friendNotificationsCount, setFriendNotificationsCount] = useState(0);
  const [meetingNotificationsCount, setMeetingNotificationsCount] = useState(0);
  const [ratingNotificationsCount, setRatingNotificationsCount] = useState(0);

  useEffect(() => {
    if (!currentUser) return;

    const db = getFirestore();
    const userDocRef = doc(db, 'users', currentUser.uid);

    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const notifications = docSnapshot.data().notifications || [];

        const friendNotifications = notifications.filter(notification => notification.type === 'friend_request');
        const meetingNotifications = notifications.filter(notification => notification.type === 'meeting_initiated');
        const ratingNotifications = notifications.filter(notification => notification.type === 'rating_pending');

        setFriendNotificationsCount(friendNotifications.length);
        setMeetingNotificationsCount(meetingNotifications.length);
        setRatingNotificationsCount(ratingNotifications.length);
      }
    });

    return () => unsubscribe();
  }, [currentUser]);

  const navigateTo = async (path) => {
    if (path === '/ratings') {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', currentUser.uid);

      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const notifications = userDoc.data().notifications || [];
        const updatedNotifications = notifications.filter(notification => notification.type !== 'rating_pending');

        await updateDoc(userDocRef, {
          notifications: updatedNotifications
        });

        setRatingNotificationsCount(0);
      }
    }

    navigate(path);
  };

  const handleKeyPress = (event, path) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigateTo(path);
    }
  };

  return (
    <div className="profile-page-container">
      {/* Titre de la page de profil */}
      <div className="profile-title-container">
        <h1>Profile</h1>
      </div>

      {/* Section pour les informations du profil et les actions */}
      <div className="profile-sections-container">
        <div className="profile-sections">
          <div
            className="profile-section"
            onClick={() => navigateTo('/profile-form')}
            onKeyPress={(event) => handleKeyPress(event, '/profile-form')}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faUser} />
            <h2>My Profile</h2>
          </div>
          <div
            className="profile-section"
            onClick={() => navigateTo('/friends')}
            onKeyPress={(event) => handleKeyPress(event, '/friends')}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faUserFriends} />
            <h2>Friends</h2>
            {friendNotificationsCount > 0 && (
              <span className="notification-count">{friendNotificationsCount}</span>
            )}
          </div>
          <div
            className="profile-section"
            onClick={() => navigateTo('/meetings')}
            onKeyPress={(event) => handleKeyPress(event, '/meetings')}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faHeart} />
            <h2>Meetings</h2>
            {meetingNotificationsCount > 0 && (
              <span className="notification-count">{meetingNotificationsCount}</span>
            )}
          </div>
          <div
            className="profile-section"
            onClick={() => navigateTo('/favorite-groups')}
            onKeyPress={(event) => handleKeyPress(event, '/favorite-groups')}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faStar} />
            <h2>Favorite Groups</h2>
          </div>
          <div
            className="profile-section"
            onClick={() => navigateTo('/ratings')}
            onKeyPress={(event) => handleKeyPress(event, '/ratings')}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faCommentDots} />
            <h2>Ratings</h2>
            {ratingNotificationsCount > 0 && (
              <span className="notification-count">{ratingNotificationsCount}</span>
            )}
          </div>
          <div
            className="profile-section"
            onClick={() => navigateTo('/settings')}
            onKeyPress={(event) => handleKeyPress(event, '/settings')}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faCog} />
            <h2>Settings</h2>
          </div>
        </div>
      </div>

      {/* Message de développement placé en dehors des sections principales */}
      <div className="profile-footer-container">
        <div className="profile-development-note">
          <p className="profile-development-message"><strong>We&apos;re in development! 🚀</strong></p>
          <p>If you encounter any issues or have suggestions, feel free to reach out to us at <a href="mailto:meevelsas@gmail.com">meevelsas@gmail.com</a>. We&apos;re constantly improving and appreciate your patience as more users join the app. 😊</p>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;