// src/components/WelcomeMessage.js
import React from 'react';
import './WelcomeMessage.css'; // Assurez-vous de créer un fichier CSS pour le style

const WelcomeMessage = ({ onClose }) => {
  return (
    <div className="welcome-message-overlay">
      <div className="welcome-message-container">
        <h2>Welcome to Meevel!</h2>
        <p>Thank you for joining our community! Here are some tips to get started:</p>
        
        <div className="welcome-tips">
          <h3><span role="img" aria-label="home">🏠</span> Home:</h3>
          <p><span role="img" aria-label="location">📍</span> <strong>Manage localisation: </strong>Set your current location to find users and groups nearby.</p>
          <p><span role="img" aria-label="find-users">🔍</span> <strong>Find users nearby: </strong>See who is close to you and connect.</p>
          <p><span role="img" aria-label="forum-groups">💬</span> <strong>Find forum groups nearby: </strong>Discover local groups and join discussions.</p>
          <p><span role="img" aria-label="ads">🛍️</span> <strong>Advertisements nearby: </strong>See local offers and promotions.</p>

          <h3><span role="img" aria-label="groups">👥</span> Groups:</h3>
          <p><span role="img" aria-label="discuss">💬</span> <strong>Forum groups to discuss: </strong>Join forums to share ideas, ask questions, and meet people.</p>

          <h3><span role="img" aria-label="map">🗺️</span> User map:</h3>
          <p><span role="img" aria-label="connect">🔗</span> <strong>Find users nearby and connect them: </strong>Use the map to locate and reach out to nearby users.</p>

          <h3><span role="img" aria-label="conversations">💬</span> Conversations:</h3>
          <p><span role="img" aria-label="chat">💬</span> <strong>Conversations: </strong>Chat with your friends or join group chats to stay connected.</p>

          <h3><span role="img" aria-label="profile">👤</span> Profile:</h3>
          <p><span role="img" aria-label="info">ℹ️</span> <strong>Profile info: </strong>View and edit your personal information.</p>
          <p><span role="img" aria-label="friends">👥</span> <strong>Friends: </strong>Manage your friends list and send friend requests.</p>
          <p><span role="img" aria-label="meetings">📅</span> <strong>Meetings: </strong>Create or join meetings with other members.</p>
          <p><span role="img" aria-label="ratings">⭐</span> <strong>Ratings: </strong>Rate other users and check your received ratings.</p>
          <p><span role="img" aria-label="favorites">❤️</span> <strong>Favorites: </strong>Manage your favorite groups and discussions.</p>
          <p><span role="img" aria-label="settings">⚙️</span> <strong>Settings: </strong>Adjust your account settings, privacy, and preferences.</p>
        </div>
        
        <div className="welcome-note">
          <p className="development-message"><strong>We&apos;re in development! 🚀</strong></p>
          <p>If you encounter any issues or have suggestions, feel free to reach out to us at <a href="mailto:meevelsas@gmail.com">meevelsas@gmail.com</a>. We&apos;re constantly improving and appreciate your patience as more users join the app. 😊</p>
        </div>

        <button onClick={onClose} className="welcome-message-close-button">Got it!</button>
      </div>
    </div>
  );
};

export default WelcomeMessage;